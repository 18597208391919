<template>
  <b-card 
    v-can="'AgOnlProf3'"
    class="professional-card"
  >
    <b-card-header class="card-header" :class="{'border-0': !showDetails}">
      <span class="d-flex align-items-center">
        <Avatar   
          class="avatar" 
          :src="professional?.picture" 
          size="40"
        />
        <p class="ml-2">Dr<span v-if="professional.gender == 'F'">a</span>.  {{ professional.name }}</p>
      </span>

      <span class="d-flex align-items-center">
          <label 
            v-can="'AgOnlProf3'"
            class="toggle-subtitle" 
            v-if="readOnly"
          >
            Inativo
          </label>
          <label 
            v-can="'AgOnlProf3'"
            class="toggle-subtitle" 
            v-else
          >
            Ativo
          </label>

          <toggle-button
            v-can="'AgOnlProf3'"
            class="ml-1"
            :width="40"
            :height="24"
            :value="!readOnly"
            @change="changeActiveProfessional"
            :color="{ checked: '#00C773' }"
          />

          <ChevronDown class="icon" v-if="!showDetails" @click="showDetails = true"/>
          <ChevronUp class="icon" v-else @click="showDetails = false"/>
      </span>
    </b-card-header>

    <b-card-body v-if="showDetails">
      <b-row>
        <b-col cols="4">
          <label class="label-title" for="">CRM</label>
          <multiselect
            v-model="form.crm_id"
            placeholder="Selecionar"
            :options="crms"
            :option-height="40"
            :showLabels="false"
            :searchable="true"
            :internal-search="false"
            class="with-border"
            track-by="value"
            label="label"
            :disabled="readOnly"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
          <div v-if="validated && !form.crm_id" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-col>

        <b-col cols="4">
          <label class="label-title" for="">RQE <small>(Opcional)</small></label>
          <multiselect
            v-model="form.rqe_id"
            placeholder="Selecionar"
            :options="rqes"
            :option-height="40"
            :showLabels="false"
            :searchable="true"
            :internal-search="false"
            class="with-border"
            track-by="value"
            label="label"
            :disabled="readOnly"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-col>

        <b-col cols="12" class="mt-3">
          <label class="label-title" for="">Sobre mim <small>(Opcional)</small></label>
          <b-form-textarea
            class="h-text-area"
            maxlength="600"
            placeholder="Descrever"
            rows="6"
            no-resize
            v-model="form.about"
            :readonly="readOnly"
          />
        </b-col>

        <div 
          v-can="'AgOnlProf2'"
          class="wrapper-button"
        >
          <b-button
            v-if="professional?.public_schedule_professional?.id"
            variant="primary"
            size="lg"
            @click="updatePublicScheduleProfessional"
          >
            Editar
          </b-button>

          <b-button
            v-else
            variant="primary"
            size="lg"
            @click="createPublicScheduleProfessional"
            :disabled="readOnly"
          >
            Salvar
          </b-button>
        </div>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

export default {
  name: 'ProfessionalCard',
  props: {
    professional: Object,
    public_schedule_id: String
  },
  components: {
    Avatar: () => import('@/components/General/Avatar.vue'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    ChevronUp: () => import('@/assets/icons/chevron-up.svg')
  },
  created() {
    this.getCrms()
    this.getRqes()
  },
  data() {
    return {
      showDetails: this.professional.public_schedule_professional && this.professional.public_schedule_professional.id,

      crms: [],
      rqes: [],
    
      validated: false,
      form: this.getDefaultForm()
    }
  },
  computed: {
    readOnly() {
      return !this.professional?.public_schedule_professional?.active
    }
  },
  methods: {
    async getCrms(){      
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getCrms(this.professional.id);
        this.crms = response.data.map(crm => {
            return {
              label: `${crm.number}-${crm.state} `,
              value: crm.id,
              main: crm.main
            }
          })
      } catch (err) {
        this.$toast.error(err.message);
      } finally {
        if(Object.keys(this.crms).length){
          if(this.professional.public_schedule_professional && this.professional.public_schedule_professional.id){
            this.form.crm_id = this.crms.find(option => option.value === this.professional.public_schedule_professional.crm_id)
          }
          else{
            this.form.crm_id = this.crms.filter(item => item.main === true)
          }
        }
        isLoading.hide();
      }
    },

    async getRqes(){      
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getRqes(this.professional.id);
        this.rqes = response.data.map(rqe => {
          return {
            label: `${rqe.number}-${rqe.state}`,
            value: rqe.id,
            main: rqe.main
          }
        })
        this.rqes.unshift({ label: 'Selecionar', value: null })

      } catch (err) {
        this.$toast.error(err.message);
      } finally {
        if(Object.keys(this.rqes).length){
          if(this.professional.public_schedule_professional && this.professional.public_schedule_professional.id){
            this.form.rqe_id = this.rqes.find(option => option.value === this.professional.public_schedule_professional.rqe_id)
          }
          else{
            this.form.rqe_id = this.rqes.filter(item => item.main === true)
          }
        }
        isLoading.hide();
      }
    },

    getDefaultForm() {
      return {
        public_schedule_id: this.public_schedule_id,
        person_id: this.professional.id,
        crm_id: null,
        rqe_id: null,
        about: this.professional.public_schedule_professional ? this.professional.public_schedule_professional.about : null
      }
    },

    changeActiveProfessional() {
      if(this.professional.public_schedule_professional) this.professional.public_schedule_professional.active = !this.professional.public_schedule_professional.active
      else {
        this.professional.public_schedule_professional = {active: true}
      }

      if(this.professional?.public_schedule_professional?.id) this.updatePublicScheduleProfessional(this.professional?.public_schedule_professional?.id)
    },
    
    getFormRequestValues(){
      if (typeof this.form.crm_id === 'object') this.form.crm_id = this.form.crm_id ? this.form.crm_id.value : null
      if (typeof this.form.rqe_id === 'object') this.form.rqe_id = this.form.rqe_id ? this.form.rqe_id.value : null
      this.form.active = this.professional.public_schedule_professional.active      
    },

    async createPublicScheduleProfessional(){
      this.validated = true;
      if(!this.form.crm_id) return;
      const isLoading = this.$loading.show()
      try {
        this.getFormRequestValues()
        await this.api.createPublicScheduleProfessional(this.form);
        this.$toast.success('Profissional agendamento online criado com sucesso!');
        this.$emit('reload-public-schedule')
      } catch (err) {
        this.$toast.error(err.message);
      } finally {
        isLoading.hide();
      }
    },

    async updatePublicScheduleProfessional(id){
      this.validated = true;
      if(!this.form.crm_id) return;
      const isLoading = this.$loading.show();
      try {               
        this.getFormRequestValues()
        await this.api.updatePublicScheduleProfessional(this.professional.public_schedule_professional.id, this.form);
        this.$toast.success('Profissional agendamento online editado com sucesso!');
        this.$emit('reload-public-schedule')
      } catch (err) {
        this.$toast.error(err.message);
      } finally {
        isLoading.hide();
      }
    }
  },
  watch: {
    'professional.public_schedule_professional': {
      handler(newValue) {
        if(newValue){
          this.form.crm_id = this.crms.find(option => option.value === newValue.crm_id)
          this.form.rqe_id = this.rqes.find(option => option.value === newValue.rqe_id)
        }
      },
      deep: true,
      immediate: true
    },
  }  
}
</script>

<style lang="scss" scoped>
  .professional-card { 
    border: 1px solid #C6CEEB;
    border-radius: 8px;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;

    p {
      font-family: 'Red Hat Display';
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 134%;
      letter-spacing: -0.01em;
      color: #525C7A;
    }
  }

  .border-0 {        
    border: 0;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }

  .icon {
    width: 28px;
    height: 28px;
    cursor: pointer;

    :hover{
        color:gray;    
    }
  }

  .label-title {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #0C1D59;
    
    small {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: #8696AC;
    }
  }

  .toggle-subtitle {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    text-align: right;
    color: #0C1D59;
    margin-top: 10px;
  }

  .wrapper-button {
    width: 100%;
    display: inline-flex;
    margin: 20px 0px 0px 0px !important;
    justify-content: flex-end;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-000);
    }
  }

  .h-text-area{
    height: 130px;
  }
</style>
